<template>
  <el-dialog title="选择模板" :visible.sync="openDialog" width="740px" :show-close="true" class="dialog-vertical"
    @close="cancel">
    <div class="tmpl-filter">
      <div class="select-filter">
        <el-select v-model="filterForm.category_id" placeholder="模板分类" @change="fliterChange">
          <el-option v-for="(item, index) in categoryArr" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="filterForm.channel" placeholder="推送渠道" @change="fliterChange">
          <el-option v-for="(item, index) in channelArr" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="keyword-filter">
        <el-input v-model="filterForm.keyword" placeholder="模板名称" suffix-icon="el-icon-search"
          @chane="fliterChange"></el-input>
      </div>
    </div>

    <div class="template-list" v-loading="loading">
      <el-radio-group style="width: 100%;margin-top: 32px;" v-model="selected">
        <div class="list-content">
          <div class="list-item cursor-pointer" :style="{
            marginRight: index%4 === 3?'0':'20px'
          }" v-for="(item, index) in tbody" :key="index" @click="handleSelect(item.id)">
            <el-image class="list-cover" :src="fixImageUrl(item.cover)" fit="cover"></el-image>
            <p class="item-name">{{item.name}}</p>
            <el-radio class="radio-select" :label="item.id">{{""}}</el-radio>
          </div>
        </div>
      </el-radio-group>
      <Pagination :total="pageData.total" :pageSize="pageData.per_page" :page="pageData.current_page"
        :change="changPage" />
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { CategoryList } from "../../api/tmpl-category/category-list";
import { templateList } from "../../api/rule/rule-detail";
import Pagination from "@/base/components/Default/Pagination";
export default {
  components: { Pagination },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      filterForm: {
        page_size: 8,
        keyword: "",
        channel: "", // 0:系统消息 1:短信消息 2:海报
        category_id: "",
      },
      categoryArr: [],
      channelArr: [
        { id: 0, name: "系统消息" },
        { id: 1, name: "短信消息" },
        { id: 2, name: "海报" },
      ],
      tbody: [],
      pageData: {},
      selected: "",
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getCategoryList();
        this.getTemplateList(this.filterForm);
        this.selected = this.selectedId;
      } else {
        this.filterForm = {
          page_size: 8,
          keyword: "",
          channel: "", // 0:系统消息 1:短信消息 2:海报
          category_id: "",
        };
      }
    },
  },
  methods: {
    // 获取分类选择列表
    getCategoryList() {
      CategoryList({ exclude_id: "" })
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    // 获取模板列表数据
    getTemplateList(requestData) {
      this.loading = true;
      this.pageData = {};
      const request = { ...requestData };
      request.channel = request.channel ? request.channel : -1;
      templateList(request)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 选择模板操作
    handleSelect(id) {
      this.selected = id;
    },
    // 筛选
    fliterChange() {
      this.getTemplateList({ ...this.filterForm });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getTemplateList(pageData);
    },
    // 保存
    submit() {
      const selectData = this.tbody.find((el) => el.id === this.selected);
      this.$emit("update:selectChange", this.selected);
      this.$emit("change", selectData);
      this.openDialog = false;
    },
    // 取消
    cancel() {},
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpl-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-input,
  .el-select {
    width: 136px;
  }

  .el-select + .el-select {
    margin-left: 20px;
  }
}
.template-list {
  width: 100%;
  min-height: 100px;
}
.list-content {
  display: flex;
  flex-wrap: wrap;
  .list-item {
    position: relative;
    flex-shrink: 0;
    margin-bottom: 20px;

    text-align: center;
    .list-cover {
      width: 160px;
      height: 90px;
    }
    .item-name {
      font-size: 14px;
      color: #3a3a3a;
      line-height: 14px;
      margin-top: 16px;
    }
    .radio-select {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.dialog-vertical {
  ::v-deep.el-radio__label {
    padding-left: 0px;
  }
}
</style>
