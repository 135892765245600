import api from "@/base/utils/request";

// 获取模板详情
export const configDetail = data => {
  return api({
    url: "/admin/blessing/rule/detail",
    method: "post",
    data
  });
};

// 保存模板详情
export const saveConfig = data => {
  return api({
    url: "/admin/blessing/rule/save",
    method: "post",
    data
  });
};

// 模板列表弹窗
export const templateList = data => {
  return api({
    url: "/admin/blessing/tmpl/tmpls",
    method: "post",
    data
  });
};

// 组织/会员选择列表
export const orgMemberSelect = data => {
  return api({
    url: "/admin/cyc/organization/multiSelect",
    method: "post",
    data,
    notCancel: true
  });
};

// 组织架构下的会员
export const MemberSelect = data => {
  return api({
    url: "/admin/cyc/organization/members",
    method: "post",
    data,
    notCancel: true

  });
};