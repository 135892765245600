<template>
  <div class="brand-content" v-loading="formLoading || selectorLoading">
    <el-form v-if="Form" size="medium" :model="Form" :rules="rules" label-position="right" label-width="80px"
      class="small-form" ref="Form" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">{{$route.params.id != 0?'编辑':'新增' + '规则'}}</p>
        <div class="modular-content">
          <el-form-item label="规则名称：" prop="name">
            <el-input v-model="Form.name" placeholder="请输入模板名称"></el-input>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>收到祝福的会员会看到这个规则名称</span>
            </div>
          </el-form-item>
          <el-form-item label="规则类型：" prop="type">
            <el-select v-model="Form.type" placeholder="请选择规则类型" @change="typeChange">
              <el-option v-for="(item,index) in typeArr" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="[3,4,5].includes(Form.type)" label-width="0px" label="" prop="type_config">
            <el-form size="medium" :model="Form.type_config" :rules="typeConfigRules" label-position="right"
              label-width="80px" class="small-form nest-form" ref="typeConfig" @submit.native.prevent>
              <el-form-item v-if="Form.type === 4" label="法定节日：" prop="holiday">
                <el-select v-model="Form.type_config.holiday" placeholder="请选择法定节假日">
                  <el-option v-for="(item, index) in holidayArr" :key="index" :value="item.id"
                    :label="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="Form.type === 5" label="24节气：" prop="holiday_24">
                <el-select v-model="Form.type_config.holiday_24" placeholder="请选择24节气">
                  <el-option v-for="(item, index) in holiday24Arr" :key="index" :value="item.id"
                    :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-form-item>
          <el-form-item v-if="Form.type !== ''" label="推送时间：" prop="push_time">
            <el-select v-if="[1,2,3,6].includes(Form.type)" v-model="Form.push_time" disabled>
              <el-option :value="Form.push_time" :label="Form.type === 3?'当天/当月推送':'当天推送'"></el-option>
            </el-select>
            <day-time-picker v-if="![0,1,2,3,6].includes(Form.type)" v-model="Form.push_time"
              placeholder="请选择推送时间">
            </day-time-picker>
            <div class="form-tip" v-if="![0,1,2,3,6].includes(Form.type)">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>选择当天推送时间</span>
            </div>
            <el-date-picker v-if="Form.type === 0" v-model="Form.push_time" value-format="timestamp"
              placeholder="请选择推送日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="![1,2,6].includes(Form.type) && Form.type !== ''" label="重复推送："
            prop="repeat_type">
            <el-select v-model="Form.repeat_type" placeholder="请选择重复推送"
              :disabled="[3,4,5].includes(Form.type)">
              <el-option v-for="(item,index) in repeatTypeArr" :key="index" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择模板：" prop="tmpl_id">
            <el-button type="default" @click="openTemplateSelector = true">
              {{Form.tmpl_id?selectTemplateData.name:'请选择模板'}}</el-button>
            <el-input v-show="false" v-model="Form.tmpl_id"></el-input>
          </el-form-item>
          <el-form-item v-if="Form.tmpl_id" label="推送渠道：" prop="channels">
            <el-checkbox-group v-model="Form.channels">
              <el-checkbox label="msg" :disabled="!selectTemplateData.channels.includes('msg')">系统消息
              </el-checkbox>
              <!-- <el-checkbox label="sms" :disabled="!selectTemplateData.channels.includes('sms')">短信通知
              </el-checkbox> -->
              <el-checkbox label="poster" :disabled="!selectTemplateData.channels.includes('poster')">海报弹窗
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>
      <div class="modular" style="padding-top: 20px;">
        <p class="modular-title">推送目标</p>
        <div class="modular-content">
          <el-form-item label="全员推送：" prop="is_send_to_whole">
            <el-switch v-model="Form.is_send_to_whole" :active-value="1" :inactive-value="0"></el-switch>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>开启后将推送给全部会员（包括后续新入会的会员）</span>
            </div>
          </el-form-item>
          <el-form-item label="选择组织：" prop="organization_ids" v-if="!Form.is_send_to_whole">
            <el-cascader v-if="organizationOptions.length" style="width: 284px"
              v-model="Form.organization_ids" :options="organizationOptions" :props="props" collapse-tags
              filterable placeholder="请选择组织（可搜索组织名称）">
            </el-cascader>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>将推送给组织架构下的会员（包括后续新入会的会员）</span>
            </div>
          </el-form-item>
          <el-form-item label="选择会员：" prop="user_position_ids"
            v-if="!Form.is_send_to_whole && Form.type !== 1">
            <el-cascader id="filterCascader" v-if="orgMemOptions.length" style="width: 284px"
              v-model="Form.user_position_ids" :options="orgMemOptions" :props="prop" collapse-tags filterable
              placeholder="请选择会员（可搜索会员名称）" :show-all-levels="false">
            </el-cascader>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <template-selector v-model="openTemplateSelector" :selectedId="Form.tmpl_id"
      :selectChange.sync="Form.tmpl_id" @change="tmplSelectChange"></template-selector>
    <FixedActionBar>
      <el-button type="default" size="medium" @click="cancel" :loading="saveLoading">取消
      </el-button>
      <el-button type="primary" size="medium" @click="formSubmit('Form')" :loading="saveLoading">保存
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import {
  configDetail,
  saveConfig,
  orgMemberSelect,
  MemberSelect,
} from "../../api/rule/rule-detail";
import { CategoryList } from "../../api/tmpl-category/category-list";
import ImageWall from "../../../common/components/SingleMediaWall.vue";
import DefaultImageSelector from "../../../common/components/DefaultImageSelector.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
import DayTimePicker from "../../components/Rule/DayTimePicker.vue";
import TemplateSelector from "../../components/Rule/TemplateSelector.vue";
export default {
  components: {
    FixedActionBar,
    ImageWall,
    DefaultImageSelector,
    DayTimePicker,
    TemplateSelector,
  },
  data() {
    return {
      Form: {
        name: "", // 名称
        type: "", // 类型（参考列表）
        type_config: {
          // 生日必填
          is_lunar: 0, // 0:阳历、1:农历
          // 法定节假日必填
          holiday: 1,
          // 24 节气必填
          holiday_24: 1,
        },
        push_time: "", // 推送时间（除了自定义，其余都是当天时间）
        repeat_type: "", // 重复类型（参考列表）
        tmpl_id: "", // 模板混淆 ID
        channels: [],
        is_send_to_whole: 0,
        organization_ids: [], // 组织 ID 数组
        user_position_ids: [], // 会员关系 ID 数组
      },
      rules: {
        name: [{ required: true, message: "请输入规则名称", trigger: "blur" }],
        type: [
          {
            required: true,
            message: "请选择规则类型",
            trigger: "change",
          },
        ],
        push_time: [
          { required: true, validator: this.passPushTime, trigger: "change" },
        ],
        repeat_type: [
          { required: true, message: "请选择重复推送", trigger: "change" },
        ],
        tmpl_id: [{ required: true, message: "请选择模板", trigger: "change" }],
        // organization_ids: [
        //   { required: true, message: "请选择组织", trigger: "change" },
        // ],
        // user_position_ids: [
        //   { required: true, message: "请选择会员", trigger: "change" },
        // ],
        channels: [
          {
            type: "array",
            required: true,
            message: "至少选一个推送渠道",
            trigger: "change",
          },
        ],
        type_config: [
          {
            required: true,
            validator: this.passTypeConfig,
            trigger: "change",
          },
        ],
      },
      typeConfigRules: {
        holiday: [
          { required: true, message: "请选择法定节假日", trigger: "change" },
        ],
        holiday_24: [
          { required: true, message: "请选择24节气", trigger: "change" },
        ],
      },
      typeArr: [
        { id: 1, name: "入会祝福" },
        { id: 2, name: "入会周年祝福" },
        { id: 3, name: "生日祝福" },
        { id: 6, name: "协会周年祝福" },
        // { id: 4, name: "法定节日祝福" },
        // { id: 5, name: "24节气祝福" },
        { id: 0, name: "自定义规则" },
      ],
      repeatTypeArr: [
        { id: 0, name: "从不" },
        { id: 1, name: "每天" },
        { id: 2, name: "每周" },
        { id: 3, name: "每两周" },
        { id: 4, name: "每月" },
        { id: 5, name: "每年" },
      ],
      // 组织联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "_children",
        checkStrictly: true,
        multiple: true,
        emitPath: false,
      },
      prop: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "_children",
        multiple: true,
        emitPath: false,
      },
      organizationOptions: [],
      orgMemOptions: [],
      holidayArr: [],
      holiday24Arr: [],
      selectTemplateData: null,
      openTemplateSelector: false,
      formLoading: false,
      selectorLoading: false,
      saveLoading: false,
    };
  },
  watch: {
    "Form.type"(val) {
      if ([3, 4, 5].includes(val)) {
        this.Form.repeat_type = 5;
      }
    },
  },
  methods: {
    // 获取详情
    getConfigDetail() {
      this.formLoading = true;
      configDetail({ id: this.$route.params.id })
        .then((res) => {
          const { data } = res;
          data.repeat_type = data.repeat_type === -1 ? "" : data.repeat_type;
          data.push_time =
            data.type === 1 ? data.push_time : data.push_time * 1000;
          this.Form = data;
          this.selectTemplateData = data.tmpl;
          this.formLoading = false;
        })
        .catch((err) => {
          this.formLoading = false;
        });
    },
    // 获取组织选择列表
    getorgMemberSelect(type) {
      this.selectorLoading = true;
      orgMemberSelect({ include_first: type })
        .then((res) => {
          if (type) {
            this.orgMemOptions = res.data;
          } else {
            this.organizationOptions = res.data;
          }
          this.selectorLoading = false;
        })
        .catch(() => {
          this.selectorLoading = false;
        });
    },
    // 获取分类选择列表
    getCategoryList() {
      CategoryList({ exclude_id: "" })
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    // 切换规则类型
    typeChange(val) {
      this.Form.push_time = "";
      this.$refs.Form.validateField("push_time");
    },
    // 模板选择后操作
    tmplSelectChange(data) {
      this.Form.channels = [];
      this.selectTemplateData = data;
    },
    // 验证推送时间
    passPushTime(rule, value, callback) {
      if (![1, 2, 3,6].includes(this.Form.type)) {
        if (value) {
          callback();
        } else {
          callback(Error("请选择推送时间"));
        }
      } else {
        callback();
      }
    },
    // 验证规则类型数据
    passTypeConfig(rule, value, callback) {
      if (value) {
        this.$refs.typeConfig.validate((valid) => {
          if (valid) {
            callback();
          } else {
            this.saveLoading = false;
          }
        });
      } else {
        callback();
      }
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.Form, id: this.$route.params.id };
          // if (
          //   !data.organization_ids.length &&
          //   !data.user_position_ids.length &&
          //   !data.is_send_to_whole
          // ) {
          //   this.$message.error("请选择推送组织或者推送会员");
          //   return false;
          // }
          data.push_time = data.type === 1 ? -1 : data.push_time / 1000;
          data.repeat_type = data.repeat_type === "" ? -1 : data.repeat_type;
          this.saveLoading = true;
          saveConfig(data)
            .then((res) => {
              this.$message.success(res.msg);
              if (this.$route.params.id == 0) {
                this.$router.push({
                  name: "BlessingRuleList",
                });
              } else {
                this.getConfigDetail();
              }

              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    // 预览
    handlePreview() {},
    // 取消
    cancel() {
      this.$router.push({
        name: "BlessingRuleList",
      });
    },
  },
  created() {
    if (this.$route.params.id != 0) {
      this.getConfigDetail();
    }
    this.getCategoryList();
    this.getorgMemberSelect(1);
    this.getorgMemberSelect(0);
  },
  //调整title
  beforeRouteEnter(to, from, next) {
    const type = to.params.type;
    switch (type) {
      case "add":
        to.meta.title = "新增";
        break;
      // case "view":
      //   to.meta.title = "查看";
      //   break;
      case "edit":
        to.meta.title = "编辑";
        break;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.el-cascader {
  ::v-deep.el-cascader__search-input {
    margin-left: 9px;
  }
  ::v-deep.el-cascader__tags .el-tag {
    max-width: 180px;
  }
}
.modular-content {
  margin-left: 11px;
}
.nest-form {
  .el-form-item {
    margin-bottom: 22px;

    &--medium:last-child {
      margin-bottom: 0px;
    }
  }
}
.brand-content {
  position: relative;
  .add-host:hover {
    border-color: #409eff;
  }

  .input-imgs {
    display: flex;
  }

  .footer-btn {
    @include fixed-card-bottom();
    padding-left: 250px;
  }

  .input-item {
    width: 194px;
    text-align: center;
    margin-right: 100px;

    .images {
      width: 194px;
      height: 135px;
      cursor: pointer;
    }

    .upload-btn {
      width: 194px;
      height: 135px;
      border: 1px dashed #d9d9d9;
      .plus-icon {
        font-size: 20px;
        .upload-text {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .info-title {
      font-size: 14px;
      line-height: 30px;
    }

    .img-tips {
      margin-top: 8px;
      font-size: 12px;
      color: #c0c4cc;
      line-height: 15px;
    }
    .upload-btn:hover {
      border-color: #409eff;
    }
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
.img-tips {
  margin-top: 8px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 15px;
}
.poster-preview {
  position: absolute;
  left: 500px;
  top: 530px;
  .potser-bg {
    width: 300px;
    height: 674px;
  }
  .preview-title {
    margin-top: 20px;
    font-size: 18px;
    color: #353535;
    line-height: 18px;
    width: 100%;
    text-align: center;
  }
}

.custom-form {
  display: flex;
  // align-items: center;
  margin-bottom: 22px;
  .form-tip {
    margin-top: 0px;
  }
  .el-form-item + .el-form-item {
    margin-left: 20px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  p {
    width: 80px;
    text-align: end;
    font-size: 14px;
    line-height: 34px;
    white-space: nowrap;
    color: #606266;
  }
  ::v-deep.el-input__suffix {
    right: 10px;
  }
}
</style>
