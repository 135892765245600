<template>
  <el-time-picker v-model="value" value-format="timestamp" :format="format" :placeholder="placeholder"
    @change="change">
  </el-time-picker>
</template>

<script>
export default {
  model: {
    prop: "time",
    event: "changeTime",
  },
  props: {
    time: {
      type: [Number, String],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "HH:mm",
    },
    change: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dayStartTime: new Date(
        new Date(new Date().toLocaleDateString()).getTime()
      ).getTime(),
    };
  },
  computed: {
    value: {
      get() {
        return this.time ? this.dayStartTime + this.time : this.time;
      },
      set(val) {
        this.$emit("changeTime", val ? val - this.dayStartTime : "");
      },
    },
  },
};
</script>

<style>
</style>